import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { Observable, map, of, throwError } from 'rxjs';
import { SnackbarConfig, TiimeSnackbarService } from 'tiime-components';

import { WindowService } from '@core';
import {
  AuthMechanismEnum,
  Biller,
  BillerConnection,
} from '@core/models/billers';
import { BillersService } from '@core/services/billers';

import {
  BillerConnectionFormData,
  BillerConnectionFormDialogComponent,
} from './biller-connection-form-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BillerConnectionService {
  private billersService = inject(BillersService);
  private windowService = inject(WindowService);
  private dialog = inject(MatDialog);
  private snackbarService = inject(TiimeSnackbarService);

  openBillerConnection(
    biller: Biller,
    connection?: BillerConnection,
  ): Observable<string | void> {
    switch (biller.authMechanism) {
      case AuthMechanismEnum.CREDENTIALS:
        return this.openBillerConnectionFormDialog(biller, connection);
      case AuthMechanismEnum.WEBAUTH:
        return this.handleBillerConnection(biller, connection).pipe(
          map((billerConnection: BillerConnection) =>
            this.windowService.goToUrl(billerConnection.authUrl),
          ),
        );
      default:
        return throwError(
          () => 'This authentication mechanism is not implemented',
        );
    }
  }

  openBillerConnectionFormDialog(
    biller: Biller,
    connection?: BillerConnection,
  ): Observable<string> {
    return this.dialog
      .open<
        BillerConnectionFormDialogComponent,
        BillerConnectionFormData,
        string
      >(BillerConnectionFormDialogComponent, {
        data: {
          biller,
          connection,
        },
        width: '550px',
        height: '543px',
      })
      .beforeClosed();
  }

  handleBillerWebauthResponse(queryParams: Params): Observable<void> {
    if (queryParams.error) {
      this.snackbarService.open(
        'Une erreur est survenue, merci de réessayer plus tard',
        SnackbarConfig.error,
      );
      return of(null);
    }

    return this.billersService.patchBillerConnections({
      state: queryParams.state as string,
      authorization_code: queryParams.code as string,
      redirect_uri: this.getRedirectUri(),
    });
  }

  private handleBillerConnection(
    biller: Biller,
    connection?: BillerConnection,
  ): Observable<BillerConnection> {
    const field = {
      name: 'redirectUri',
      value: this.getRedirectUri(),
      position: 0,
    };
    if (connection) {
      return this.billersService.update(biller.id, connection.id, [field]);
    }
    return this.billersService.create(biller.id, [field]);
  }

  /** Always replace http by https for dev testing as Amazon only allows https sources */
  private getRedirectUri(): string {
    const origin = this.windowService.origin;
    return `${origin.replace(/https|http/, 'https')}/biller-callback`;
  }
}
