import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Mapper, NgUtils } from '@manakincubber/tiime-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { BillersService } from '@core/services/billers/billers.service';
import { Biller, BillerConnection, BillerField } from '@models/billers';

export interface BillerConnectionFormData {
  biller: Biller;
  connection?: BillerConnection;
}

@UntilDestroy()
@Component({
  selector: 'app-biller-connection-form-dialog',
  templateUrl: './biller-connection-form-dialog.component.html',
  styleUrls: ['./biller-connection-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillerConnectionFormDialogComponent {
  readonly fields$ = this.billersService
    .getFieldsForBiller(this.data.biller.id)
    .pipe(
      tap(fields => {
        this.initForm(fields);
        this.formLoading$.next(false);
      }),
    );

  readonly trackByIndex = NgUtils.trackByIndex;
  readonly loading$ = new BehaviorSubject(false);
  readonly formLoading$ = new BehaviorSubject(true);

  readonly form = new FormGroup({});

  readonly mapToFieldIsHTMLInput: Mapper<string, boolean> = (
    fieldType: string,
  ) => ['text', 'password', 'email'].includes(fieldType);

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: BillerConnectionFormData,
    private readonly dialogRef: MatDialogRef<BillerConnectionFormDialogComponent>,
    private readonly billersService: BillersService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }

  initForm(fields: BillerField[]): void {
    fields.forEach(field => {
      this.form.addControl(field.name, new FormControl());
    });
  }

  submit(): void {
    if (this.form.invalid || this.loading$.value === true) {
      return;
    }
    this.loading$.next(true);
    const fields: Partial<BillerField>[] = Object.entries(this.form.value).map(
      ([name, value]: [string, string], position) => ({
        name,
        value,
        position,
      }),
    );
    const submit$ = this.data.connection
      ? this.billersService.update(
          this.data.biller.id,
          this.data.connection.id,
          fields,
        )
      : this.billersService.create(this.data.biller.id, fields);

    submit$
      .pipe(
        tap(({ biller }) => this.dialogRef.close(biller?.name)),
        finalize(() => this.dialogRef?.close()),
      )
      .subscribe();
  }
}
