import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GetOptions,
  PaginationData,
  RequiredGetOptions,
} from 'tiime-components';

import { TemporaryEncoder } from '@core/temporary-encoder';
import { ApiAlertError } from '@decorators/api-alert-error';
import { HttpHelper } from '@helpers';
import {
  Biller,
  BillerApiContract,
  BillerConnection,
  BillerConnectionApiContract,
  BillerConnectionResponse,
  BillerField,
} from '@models/billers';

@Injectable({
  providedIn: 'root',
})
export class BillersService {
  private readonly resource = 'api/v1/companies/{companyId}/billers';

  constructor(private readonly http: HttpClient) {}

  @ApiAlertError()
  getAllConnections(
    getOptions: RequiredGetOptions<'range'>,
  ): Observable<PaginationData<BillerConnection>> {
    const url = `${this.resource}/all/connections`;
    const partialOptions = new GetOptions(getOptions).toHttpGetOptions();
    const options = {
      params: new HttpParams({
        fromObject: partialOptions.params,
        encoder: new TemporaryEncoder(),
      }),
      headers: {
        ...partialOptions.headers,
      },
    };
    return this.http
      .get(url, { ...options, observe: 'response' })
      .pipe(
        HttpHelper.mapToPaginationData(
          getOptions.range,
          (json: BillerConnectionApiContract) =>
            BillerConnection.fromJson(json),
        ),
      );
  }

  @ApiAlertError()
  getAll(): Observable<Biller[]> {
    return this.http
      .get<BillerApiContract[]>('api/v1/billers', {
        headers: new HttpHeaders('Accept: application/vnd.billers.v2+json'),
      })
      .pipe(map(json => json.map(biller => Biller.fromJson(biller))));
  }

  @ApiAlertError()
  getBillersForCompany(): Observable<Biller[]> {
    const url = `${this.resource}/all/connections`;
    return this.http
      .get<BillerConnectionApiContract[]>(url, {
        headers: new HttpHeaders('Accept: application/vnd.billers.v2+json'),
      })
      .pipe(
        map(json =>
          json
            .map(connection => Biller.fromJson(connection.biller))
            .filter(
              (biller, i, billers) =>
                billers.findIndex(b => b.id === biller.id) === i,
            ),
        ),
      );
  }

  @ApiAlertError()
  getFieldsForBiller(billerId: number): Observable<BillerField[]> {
    const url = `api/v1/billers/${billerId}/fields`;
    return this.http.get<BillerField[]>(url);
  }

  @ApiAlertError()
  create(
    billerId: number,
    fields: Partial<BillerField>[],
  ): Observable<BillerConnection> {
    const url = `${this.resource}/${billerId}/connections`;
    return this.http
      .post<BillerConnectionApiContract>(url, { fields })
      .pipe(map(json => BillerConnection.fromJson(json)));
  }

  @ApiAlertError()
  update(
    billerId: number,
    connectionId: number,
    fields: Partial<BillerField>[],
  ): Observable<BillerConnection> {
    const url = `${this.resource}/${billerId}/connections/${connectionId}`;
    return this.http
      .patch<BillerConnectionApiContract>(url, { fields })
      .pipe(map(json => BillerConnection.fromJson(json)));
  }

  @ApiAlertError()
  delete(billerId: number, connectionId: number): Observable<unknown> {
    const url = `${this.resource}/${billerId}/connections/${connectionId}`;
    return this.http.delete(url);
  }

  @ApiAlertError()
  patchBillerConnections(infos: BillerConnectionResponse): Observable<void> {
    return this.http.patch<void>('api/v1/companies/biller-connections', {
      state: infos.state,
      authorization_code: infos.authorization_code,
      redirect_uri: infos.redirect_uri,
    });
  }
}
