export enum AuthMechanismEnum {
  CREDENTIALS = 'credentials',
  WEBAUTH = 'webauth',
}

export interface BillerApiContract {
  id: number;
  name: string;
  logo_url: string;
  authentication_mechanism: AuthMechanismEnum;
}

export class Biller {
  constructor(
    public id: number,
    public name: string,
    public logoUrl: string,
    public authMechanism: AuthMechanismEnum,
  ) {}

  static fromJson(json: BillerApiContract): Biller {
    return new Biller(
      json.id,
      json.name,
      json.logo_url,
      json.authentication_mechanism,
    );
  }
}
