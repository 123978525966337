<tiime-dialog-close-button
  data-cy="dialog__btn-close"
></tiime-dialog-close-button>

<div *tiimeLet="fields$ | async as fields" class="dialog-container">
  <img
    class="biller__logo"
    data-cy="biller-connection-form-dialog__img-biller-logo"
    [alt]="data.biller.name"
    [src]="data.biller.logoUrl"
  />
  <div class="title" mat-dialog-title data-cy="dialog-title">
    {{ this.data.isUpdate ? 'Mettre à jour' : 'Synchroniser' }} votre compte
    <br/>
    {{ data.biller.name }}
  </div>
  <div class="hint" data-cy="biller-connection-form-dialog__txt-hint">
    Vos données sont chiffrées, nous n'avons pas accès aux données échangées
    avec ce fournisseur.
  </div>
  <form class="biller__form" [formGroup]="form" (ngSubmit)="submit()">
    <div
      *ngIf="(formLoading$ | async) === false; else loader"
      class="form-container"
    >
      <div
        *ngFor="let field of fields; trackBy: trackByIndex"
        class="biller__form--field"
      >
        <label
          class="biller__form--label"
          data-cy="biller-connection-form-dialog__label-input"
        >
          {{ field.label }}
        </label>
        <ng-container [ngSwitch]="field.type">
          <input
            *ngSwitchCase="'password'"
            appPasswordToggle
            data-cy="biller-connection-form-dialog__input-password"
            [type]="field.type"
            [pattern]="field.regex"
            [formControlName]="field.name"
            [required]="field.required"
          />
          <tiime-input-container *ngSwitchCase="'list'">
            <tiime-select
              data-cy="biller-connection-form-dialog__select"
              [required]="field.required"
              [formControlName]="field.name"
              [options]="field.values"
            ></tiime-select>
          </tiime-input-container>
          <input
            *ngSwitchDefault
            data-cy="biller-connection-form-dialog__input"
            [type]="field.type"
            [pattern]="field.regex"
            [formControlName]="field.name"
            [required]="field.required"
          />
        </ng-container>
      </div>
    </div>
    <ng-template #loader>
      <div class="biller__form--loader">
        <mat-progress-spinner
          diameter="18"
          color="primary"
          mode="indeterminate"
        ></mat-progress-spinner>
      </div>
    </ng-template>
    <div mat-dialog-actions>
      <button
        tiime-button
        neutral
        inverted
        data-cy="biller-connection-form-dialog__btn-cancel"
        [mat-dialog-close]=""
      >
        Annuler
      </button>
      <button
        tiime-button
        accent
        data-cy="biller-connection-form-dialog__btn-submit"
        [disabled]="
          form.pristine || form.invalid || (loading$ | async) === true
        "
      >
        {{ data.isUpdate ? 'Mettre à jour' : 'Connecter' }}
      </button>
    </div>
  </form>
</div>
