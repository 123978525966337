import { BillerConnectionStatus } from '@enums';

import { Biller, BillerApiContract } from './biller';
import { BillerField } from './biller-field';

export interface BillerConnectionResponse {
  state: string;
  authorization_code: string;
  redirect_uri: string;
}

export interface BillerConnectionApiContract {
  id?: number;
  name?: string;
  status?: BillerConnectionStatus;
  synchronization_date?: string;
  authorization_url?: string;
  fields: BillerField[];
  biller: BillerApiContract;
}

export class BillerConnection {
  constructor(
    public id?: number,
    public name?: string,
    public status?: BillerConnectionStatus,
    public synchronizationDate?: string,
    public authUrl?: string,
    public fields?: BillerField[],
    public biller?: Biller,
  ) {}

  static fromJson(json: BillerConnectionApiContract): BillerConnection {
    return new BillerConnection(
      json.id,
      json.name,
      json.status,
      json.synchronization_date,
      json.authorization_url,
      json.fields,
      Biller.fromJson(json.biller),
    );
  }

  static toJson(
    connection: BillerConnection,
  ): Partial<BillerConnectionApiContract> {
    return {
      fields: connection.fields,
    };
  }
}
